<template>
  <div id="login-page-content">
    <div id="form-content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginPage'
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_mixins.scss";

#form-content {
  @include center;

  min-height: 100vh;
}

@media (min-width: $sm) {
  #login-page-content {
    background-image: url($cdn + "/branding/login-background-image.jpg");
    background-size: cover;
  }
}
</style>
