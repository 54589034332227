export const systemSelectionTypes = {
  EXISTING_SYSTEM: 'EXISTING_SYSTEM',
  NEW_SYSTEM: 'NEW_SYSTEM',
  DNE: 'DNE',
  SELECTED_SYSTEM: 'SELECTED_SYSTEM'
}

export const institutionSelectionTypes = {
  EXISTING_INSTITUTION: 'EXISTING_INSTITUTION',
  NEW_INSTITUTION: 'NEW_INSTITUTION'
}

export const purchaseOrderStatus = {
  TRIAL: 'TRIAL',
  PAID: 'PAID',
  PILOT: 'PILOT'
}
