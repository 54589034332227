<template>
  <SimseiApp>
    <LoginPage />
  </SimseiApp>
</template>

<script>
import LoginPage from '@/login/components/LoginPage'

export default {
  name: 'LoginApp',
  components: { LoginPage }
}
</script>

<style lang="scss">
#login {
  font-family: "Open Sans", sans-serif;
  color: $neutral-typography-dark;
  letter-spacing: 0.2px;
}
</style>
