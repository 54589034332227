export const eventTypes = {
  LAB: 'LAB',
  OTHER: 'OTHER'
}

export const activityTypes = {
  PRE: 'PRE',
  PRACTICE: 'PRACTICE',
  POST: 'POST'
}
