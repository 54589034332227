const userRoles = Object.freeze({
  WORKSHOP_ADMIN: { value: 'WORKSHOP_ADMIN', text: 'Workshop Admin', role: 'ROLE_WORKSHOP_ADMIN' },
  CLINICAL_EDUCATION: { value: 'CLINICAL_EDUCATION', text: 'Clinical Education', role: 'ROLE_CLINICAL_EDUCATION' },
  FACILITATOR: { value: 'FACILITATOR', text: 'Facilitator', role: 'ROLE_FACILITATOR' },
  PARTICIPANT: { value: 'PARTICIPANT', text: 'Participant', role: 'ROLE_PARTICIPANT' }
})

export default userRoles

export function isWorkshopRole (role) {
  return Object.keys(userRoles).includes(role)
}
