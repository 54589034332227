import { useAuthStore as useResidencyAuthStore } from '@/residency/stores/auth'
import { useMeStore as useResidencyMeStore } from '@/residency/stores/me'
import { useAuthStore as useWorkshopAuthStore } from '@/workshop/stores/auth'
import { useMeStore as useWorkshopMeStore } from '@/workshop/stores/me'
import { useUserStore as useResidencyUserStore } from '@/residency/stores/user'
import { loginErrorProps } from '@/components/alert/props/login-props'
import axios from 'axios'

/**
 * This function scores the error based on the status code and error description.
 * The lower the score, the higher the priority. The highest priority errors
 * are the ones that are more specific.
 *
 * @param {Object} errorResponse - The error response object returned by Axios
 * @returns int - score for the error
 */
const scoreLoginError = (errorResponse) => {
  const statusCode = errorResponse.status
  const errorDescription = errorResponse.data.error_description

  // If one of the services is down, we want to prioritize that error
  // regardless of how the other request failed.
  if (statusCode === 500) {
    return 0
  }

  // The user will be locked out of both residency and workshop,
  // if they have too many failed login attempts
  if (statusCode === 400 && errorDescription.includes('User account is locked')) {
    return 25
  }

  // If the user is disabled, we want to prioritize that error
  // over 'Bad credentials' from the other service
  if (statusCode === 400 && errorDescription.includes('User is disabled')) {
    return 50
  }

  // If the user doesn't have an active program or the role is under maintenance
  if (statusCode === 404 || statusCode === 401) {
    return 75
  }

  // Bad credentials is the most generic error, so we want keep it as the last priority
  if (statusCode === 400 && errorDescription.includes('Bad credentials')) {
    return 100
  }

  return 1000
}

export function useLoginUtils () {
  const residencyAuthStore = useResidencyAuthStore()
  const workshopAuthStore = useWorkshopAuthStore()
  const residencyMeStore = useResidencyMeStore()
  const workshopMeStore = useWorkshopMeStore()
  const residencyUserStore = useResidencyUserStore()

  async function submitLogin (loginForm) {
    loginForm.username = loginForm.username.trim()
    const residencyLoginReq = requestToken({
      loginForm,
      baseApi: process.env.VUE_APP_BASE_API
    })
    const workshopLoginReq = requestToken({
      loginForm,
      baseApi: process.env.VUE_APP_BASE_WORKSHOP_API
    })

    const [residencyLoginResp, workshopLoginResp] = await Promise.allSettled([
      residencyLoginReq,
      workshopLoginReq
    ])

    if (residencyLoginResp.status === 'fulfilled' && workshopLoginResp.status === 'fulfilled') {
      return {
        residency: residencyLoginResp.value.data,
        workshop: workshopLoginResp.value.data
      }
    } else if (residencyLoginResp.status === 'fulfilled') {
      const authToken = residencyLoginResp.value.data
      const loginResult = await residencyLogin(authToken)
      return { residency: loginResult }
    } else if (workshopLoginResp.status === 'fulfilled') {
      const authToken = workshopLoginResp.value.data
      const loginResult = await workshopLogin(authToken)
      return { workshop: loginResult }
    } else { // Else both requests have failed
      residencyAuthStore.clearAuthState()
      workshopAuthStore.clearState()
      // If both requests failed, we want to rethrow the error with the most specific error message.
      // The scoreLoginError will return a priority score for the error, which is then used to compare
      // the responses errors. The error with the lowest score is the most specific error.
      const residencyResponse = residencyLoginResp.reason
      const workshopResponse = workshopLoginResp.reason
      const error = scoreLoginError(residencyResponse.response) < scoreLoginError(workshopResponse.response)
        ? residencyResponse
        : workshopResponse
      throw error
    }
  }

  async function resetPasswordEmail (email) {
    let residencyErr = null
    try {
      const residencyResp = await residencyUserStore.resetPasswordEmail(email)
      if (residencyResp.status === 204) {
        return residencyResp
      }
    } catch (err) {
      residencyErr = err
    }

    // If the email address is not found in residency, we want to try the workshop.
    let workshopErr = null
    try {
      const workshopResp = await workshopMeStore.resetPasswordEmail(email)
      if (workshopResp.status === 200) {
        return workshopResp
      }
    } catch (err) {
      workshopErr = err
    }

    // If both requests failed, we want to rethrow the error with the most specific error message.
    // These endpoints can either return 404 or 403. 403 is more specific than 404,
    // so we want to prioritize that error.
    throw residencyErr.status < workshopErr.status
      ? residencyErr
      : workshopErr
  }

  async function resetPassword ({ resetToken, password }) {
    const residencyUserStore = useResidencyUserStore()
    const workshopMeStore = useWorkshopMeStore()
    const [residencyResp, workshopResp] = await Promise.allSettled([
      residencyUserStore.resetPassword({ resetToken, password }),
      workshopMeStore.resetPassword({ resetToken, password })
    ])

    if (residencyResp.status !== 'fulfilled' && workshopResp.status !== 'fulfilled') {
      // These endpoints can return 404 or 422. 422 is more specific than 404,
      // so we want to prioritize that error.
      throw residencyResp.response.status > workshopResp.response.status
        ? residencyResp
        : workshopResp
    }
  }

  async function clearAllStates () {
    residencyAuthStore.clearState()
    workshopAuthStore.clearState()
  }

  function getLoginErrorProps (error) {
    let errorProps = {}
    if (!error.response) {
      errorProps = loginErrorProps.connectionError
    } else if (error.response.status === 400 && error.response.data.error_description === 'Bad credentials') {
      errorProps = loginErrorProps.badCredentiaslError
    } else if (error.response.status === 400 && error.response.data.error_description === 'User account is locked') {
      errorProps = loginErrorProps.userLockedError
    } else if (error.response.status === 400 && error.response.data.error_description === 'User is disabled') {
      errorProps = loginErrorProps.userDisabledError
    } else if (error.response.status === 401) {
      if (error.response.data.error_description === 'Program or user group has not yet begun.') {
        errorProps = loginErrorProps.unstartedProgramError
      } else if (error.response.data.error_description === 'This user role is currently under maintenance.') {
        errorProps = loginErrorProps.userRoleMaintenanceError
      } else if (error.response.data.error_description === 'User is disabled') {
        errorProps = loginErrorProps.userDisabledError
      }
    } else if (error.response.status === 404) {
      errorProps = loginErrorProps.notFoundError
    } else if (error.response.status >= 500) {
      errorProps = loginErrorProps.internalServerError
    } else {
      errorProps = loginErrorProps.genericError
    }
    return errorProps
  }

  async function workshopLogin (authToken) {
    await workshopAuthStore.login(authToken)
    return {
      role: workshopMeStore.role
    }
  }

  async function residencyLogin (authToken) {
    await residencyAuthStore.login(authToken)
    return {
      role: residencyMeStore.role
    }
  }

  function requestToken ({ loginForm, baseApi }) {
    const credentials = new URLSearchParams()
    credentials.append('username', loginForm.username)
    credentials.append('password', loginForm.password)
    credentials.append('grant_type', loginForm.grant_type)
    const options = {
      url: baseApi + '/oauth/token',
      method: 'POST',
      withCredentials: true,
      data: credentials,
      headers: { 'Content-type': 'application/x-www-form-urlencoded' },
      auth: {
        username: process.env.VUE_APP_CLIENT_ID,
        password: process.env.VUE_APP_CLIENT_SECRET
      }
    }
    return axios(options)
  }

  return {
    submitLogin,
    resetPasswordEmail,
    resetPassword,
    clearAllStates,
    getLoginErrorProps,
    workshopLogin,
    residencyLogin
  }
}
