import { createRouter, createWebHistory } from 'vue-router'
import loginEndpoints from '@/login/router/login-endpoints'

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) { // scroll to saved position when navigating with back/forward buttons
      return savedPosition
    }
    return { left: 0, top: 0 } // scroll to top of page for all other navigation
  },
  routes: [
    {
      path: '/',
      children: [
        {
          path: '',
          name: 'LoginIndex',
          component: () => import(/* webpackChunkName: "login-form" */ '@/login/components/LoginForm')
        },
        {
          path: `/${loginEndpoints.LOGIN}`,
          name: 'LoginForm',
          component: () => import(/* webpackChunkName: "login-form" */ '@/login/components/LoginForm')
        },
        {
          path: `/${loginEndpoints.FORGOTTEN_PASSWORD}`,
          name: 'ForgottenPasswordForm',
          component: () => import(/* webpackChunkName: "forgot-pwd-form" */ '@/login/components/ForgottenPasswordForm')
        },
        {
          path: `/${loginEndpoints.RESET_PASSWORD}`,
          name: 'ResetPasswordForm',
          component: () => import(/* webpackChunkName: "reset-pwd-form" */ '@/login/components/ResetPasswordForm'),
          props: route => ({ resetToken: route.query.token })
        },
        // These routes are for users who have portal.simsei.com/home, /courses url saved in
        // their browser so that they get redirected to the login page instead of a blank page.
        {
          path: `/${loginEndpoints.HOME}`,
          redirect: { name: 'LoginIndex' }
        },
        {
          path: `/${loginEndpoints.COURSES}`,
          redirect: { name: 'LoginIndex' }
        }
      ]
    }
  ]
})

export default router
