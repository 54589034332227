export function toCapitalCase (str) {
  return str.replace(/\b\w+/g, txt => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

/* To Title Case © 2018 David Gouch | https://github.com/gouch/to-title-case */
export function toTitleCase (str) {
  const smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|v.?|vs.?|via)$/i
  const alphanumericPattern = /([A-Za-z0-9\u00C0-\u00FF])/
  const wordSeparators = /([ :–—-])/

  return str.replace('_', ' ').toLowerCase().split(wordSeparators)
    .map(function (current, index, array) {
      if (
        /* Check for small words */
        current.search(smallWords) > -1 &&
        /* Skip first and last word */
        index !== 0 &&
        index !== array.length - 1 &&
        /* Ignore title end and subtitle start */
        array[index - 3] !== ':' &&
        array[index + 1] !== ':' &&
        /* Ignore small words that start a hyphenated phrase */
        (array[index + 1] !== '-' ||
          (array[index - 1] === '-' && array[index + 1] === '-'))
      ) {
        return current.toLowerCase()
      }

      /* Capitalize the first letter */
      return current.replace(alphanumericPattern, function (match) {
        return match.toUpperCase()
      })
    })
    .join('')
}

export function toKebabCase (str) {
  return str.replace(/\s+/g, '-').toLowerCase()
}

export function removeSpecialCharacters (str) {
  return str.replace(/[^\w-]/gi, '')
}

export function toPhoneNumber (str) {
  str = str.toString().replace(/\D/g, '')
  const match = str.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (!match) return
  return `(${match[1]}) ${match[2]}-${match[3]}`
}

/**
 * Convert the words of a string to lowercase *unless* there's an acronym or a non-leading capital letter.
 *
 * Example: 'One two THREE FoUR, Five-sIX, (seVEn EiGHt) nIne'
 * Becomes: 'one two THREE FoUR, five-sIX, (seVEn EiGHt) nIne'
 */
export function toMidSentenceCase (str) {
  return str.replace(/\w+/g, toLower)
}

export function formatFileSize (size) {
  const kbs = Math.round(size / 10) / 100
  if (kbs > 1000000) {
    const mbs = Math.round(size / 10000000) / 100
    return `${mbs}GB`
  } else if (kbs > 1000) {
    const mbs = Math.round(size / 10000) / 100
    return `${mbs}MB`
  }
  return `${kbs}KB`
}

function toLower (str) {
  return shouldStayCapitalized(str) ? str : str.toLowerCase()
}

function shouldStayCapitalized (str) {
  const regex = /.+[A-Z].*/g
  return regex.test(str)
}

/**
 * Convert a string from 'HH:MM:SS: into total number of seconds
 * Example: '00:01:05' becomes 65
 */
export function parseTimeToSeconds (time) {
  // Parse and collect timeToComplete into form.secondsToComplete
  let [hours, minutes, seconds] = time.split(':').map(period => parseInt(period))
  if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
    throw new Error('Invalid time string')
  }
  minutes += hours * 60
  seconds += minutes * 60
  return seconds
}

/**
 * Checks if string contains only whitespace and no characters
 */
export function containsOnlyWhiteSpaceCharacters (str) {
  return /^\s*$/.test(str)
}

/**
 * Removes the <a> and <script> tags from a string
 * Example: '<a href="http://someurl.com"> someText </a> becomes 'someText
 */
export function sanitizeInputFromAnchorAndScriptTags (str) {
  return str.replace(/<\/?a[^>]*>|<script[^>]*>([\s\S]*?)<\/script>/gi, '$1')
}

/**
 * Generates a string from a random set of bytes
 * This function is to support random string generation on Safari 15, becuase it does not support crypto.randomUUID()
 */
export function randomString () {
  const array = new Uint8Array(16)
  crypto.getRandomValues(array)
  return String.fromCharCode.apply(null, array)
}

/*
  * Generates a random string with numbers and lower-case letters.
  */
export function randomAlphanumericString () {
  return Math.random().toString(36).slice(2)
}
