export const attendeeStatusEnums = {
  NEW: 'NEW',
  AUDITOR: 'AUDITOR',
  IN_SYSTEM: 'IN_SYSTEM'
}

export const paymentStatusEnums = {
  NOT_REQUIRED: 'NOT_REQUIRED',
  PENDING_REGISTRATION: 'PENDING_REGISTRATION',
  NOT_PROCESSED: 'NOT_PROCESSED',
  PAID: 'PAID'
}
