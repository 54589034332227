import { parseDateAndTime, convertToUtc } from '@/utils/date-util'
import { useMeStore } from '@/residency/stores/me'
import { useAdminStore } from '../stores/admin/admin'

/**
 * @param str date object in str format
 * @returns eg: 'Apr 29, 2020, 8:00 AM'
 */
export function toShortMonthDayYearAndTime (str) {
  const date = toProgramTimezone(convertToUtc(str))
  const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' }
  return date.toLocaleDateString('en-US', options)
}

/**
 * @param str date object in str format
 * @returns eg: 'April 29, 2020 • 8:00 AM'
 */
export function toBulletSeperatedDateAndTime (str) {
  const date = toProgramTimezone(convertToUtc(str))
  const options = { year: 'numeric', month: 'long', day: 'numeric' }
  return date.toLocaleDateString('en-US', options) + ' • ' + toTime(str)
}

/**
 * @param str date object in str format
 * @returns eg: 'Apr 29, 2020 • 8:00 AM'
 */
export function toBulletSeperatedShortDateAndTime (str) {
  return toShortMonthDayYear(str) + ' • ' + toTime(str)
}

/**
 * @param str date object in str format
 * @returns eg: 'April 29, 2020'
 */
export function toMonthDayYear (str, programTime = true) {
  const date = programTime ? toProgramTimezone(convertToUtc(str)) : new Date(str)
  const options = { year: 'numeric', month: 'long', day: 'numeric' }
  return date.toLocaleDateString('en-US', options)
}

/**
 * @param str date object in str format
 * @returns eg: 'Apr 29, 2020'
 */
export function toShortMonthDayYear (str) {
  const date = toProgramTimezone(convertToUtc(str))
  const options = { year: 'numeric', month: 'short', day: 'numeric' }
  return date.toLocaleDateString('en-US', options)
}

/**
 * @param str date object in str format
 * @param timezone timezone to convert date to
 * @returns eg: 'Fri, Apr 29, 2020'
 */
export function toWeekdayShortMonthDayYear (str, timezone) {
  const date = toTimezone(new Date(str), timezone)
  const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }
  return date.toLocaleDateString('en-US', options)
}

/**
 * @param str date object in str format
 * @returns eg: 'Friday, Apr 29, 2020'
 */
export function toWeekdayLongMonthDayYear (str) {
  const date = toProgramTimezone(convertToUtc(str))
  const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' }
  return date.toLocaleDateString('en-US', options)
}

/**
 * @param str date object in str format
 * @returns eg: '29'
 */
export function getDay (str) {
  const date = toProgramTimezone(str)
  const options = { day: '2-digit' }
  return date.toLocaleDateString('en-US', options)
}

/**
  @param str date object in str format
  @returns eg: 'JAN'
 */
export function getMonth (str) {
  const date = toProgramTimezone(str)
  const options = { month: 'short' }
  return date.toLocaleDateString('en-US', options).toUpperCase()
}

/**
 * @param str date object in str format
 * @returns eg: '8:00 AM'
 */
export function toTime (str) {
  const date = toProgramTimezone(convertToUtc(str))
  const options = { hour: 'numeric', minute: 'numeric' }
  return date.toLocaleTimeString('en-US', options)
}

/**
 * @param str date object in str format
 * @param timezone the timezone to convert the given date to
 * @returns eg: '8:00 AM'
 */
export function toZonedTime (str, timezone) {
  const zonedDate = toTimezone(new Date(str), timezone)

  const options = { hour: 'numeric', minute: 'numeric' }
  return zonedDate.toLocaleTimeString('en-US', options)
}

export function toTimezone (date, timezone) {
  const utcDate = convertToUtc(date)
  return new Date(utcDate.toLocaleString('en-US', { timeZone: timezone }))
}

/**
 * Parses date and time string in local timezone
 * @param date current date in string format eg: '2020-05-20'
 * @param time current local time in string format eg: '01:00 AM'
 * @returns new date object
 */
export function parseLocaleDate (date, time) {
  const timeArray = time.split(':')
  let hour = parseInt(timeArray[0].replace(/\D/gi, ''))
  if (hour === 12) hour -= 12
  if (timeArray[1].includes('PM')) hour += 12
  hour = ('0' + hour).slice(-2)
  const minute = ('0' + timeArray[1].replace(/\D/gi, '')).slice(-2)
  const programTime = new Date(`${date}T${hour}:${minute}`)
  programTime.setMinutes(programTime.getMinutes() - getProgramTimezoneMinutesOffset())
  return programTime
}

/**
 * @param str date object in str format
 * @returns 'true' if provided date string is the same date as today; 'false' otherwise
 */
export function isToday (str) {
  const givenDate = toProgramTimezone(new Date(str))
  const today = toProgramTimezone(new Date())
  return givenDate.getDate() === today.getDate() &&
    givenDate.getMonth() === today.getMonth() &&
    givenDate.getFullYear() === today.getFullYear()
}

/**
 * @param dateObject object with date information
 * @return { time: '02:00 AM', date: '2020-01-01' } of date object
 */
export function parseDateAndTimeForProgram (dateObject) {
  if (!dateObject) return
  dateObject = toProgramTimezone(convertToUtc(dateObject))
  return parseDateAndTime(dateObject)
}

export function toProgramTimezone (dateObject) {
  let timezone
  const meStore = useMeStore()
  if (meStore.isAdministrativeRole) {
    const { adminSelectedProgram } = useAdminStore()
    const selectedProgram = adminSelectedProgram
    if (selectedProgram) {
      timezone = selectedProgram.timezoneOption.timezone
    }
  } else {
    timezone = meStore.timezone
  }
  if (!timezone) return dateObject
  return new Date(dateObject.toLocaleString('en-US', { timeZone: timezone }))
}

export function getProgramTimezoneMinutesOffset () {
  const localTimezone = new Date()
  const programTimezone = toProgramTimezone(localTimezone)
  return Math.round((programTimezone - localTimezone) / (1000 * 60))
}

/**
 * Takes a dateString and will format it into 1 of 4 states:
 *
 * 'Just now' if under 1 minute;
 * '<n> minutes ago' if under 1 hour;
 * '<n> hours ago' if under 24 hours;
 * 'September 22, 2022' (eg) if equal or greater than 24 hours
 *
 * @param {String} dateString Date string in LocalDateTime format (i.e. yyyy-mm-dd hh:mm:ss)
 * @return {String} 'Just now', '2 hours ago', '15 minutes ago', 'September 22, 2022'
 */
export function dateFormatter (dateString) {
  // Date is UTC
  const date = new Date(dateString)
  const currentTime = new Date()
  // Convert current time to UTC timezone
  const now = new Date(currentTime.getTime() + currentTime.getTimezoneOffset() * 60 * 1000)
  const timeDiff = (now.getTime() - date.getTime()) / 1000
  if (timeDiff < 60) {
    return 'Just now'
  } else if (timeDiff < 60 * 60) {
    const minutesAgo = Math.floor(timeDiff / 60)
    return `${minutesAgo} minutes ago`
  } else if (timeDiff < 60 * 60 * 24) {
    const hoursAgo = Math.floor(timeDiff / (60 * 60))
    return `${hoursAgo} hours ago`
  } else {
    return toMonthDayYear(date)
  }
}

/**
 * Determines if the given timestamp occurs today or tomorrow.
 *
 * @param {String} timestamp - The timestamp string to check
 * @returns {boolean} Returns true if the timestamp is after midnight today, or anytime tomrrow.
 */
export function timestampIsTodayOrTomorrow (timestamp) {
  const timestampDate = toProgramTimezone(new Date(timestamp))
  timestampDate.setDate(timestampDate.getDate() - 1)
  timestampDate.setHours(0, 0, 0, 0)
  const today = toProgramTimezone(new Date())
  return timestampDate <= today
}
