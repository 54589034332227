const userRoles = Object.freeze({
  RESIDENT: 'RESIDENT',
  FACULTY: 'FACULTY',
  PROG_DIRECTOR: 'PROGRAM_DIRECTOR',
  SYS_COORDINATOR: 'SYSTEM_COORDINATOR',
  SIMSEI_ADMIN: 'SIMSEI_ADMIN',
  SIMSEI_IMPLEMENTATION_MANAGER: 'SIMSEI_IMPLEMENTATION_MANAGER',
  FIELD_TEAM_MEMBER: 'FIELD_TEAM_MEMBER'
})

export default userRoles

export function isResidencyRole (role) {
  return Object.values(userRoles).includes(role)
}
