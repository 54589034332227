export const workshopSpecialtyEnums = {
  OB_GYN: 'OB/GYN',
  GENERAL: 'General',
  COLORECTAL: 'Colorectal',
  UROLOGY: 'Urology',
  OTHER: 'Other'
}

export const checkboxSpecialtyArray = [
  workshopSpecialtyEnums.OB_GYN,
  workshopSpecialtyEnums.GENERAL,
  workshopSpecialtyEnums.COLORECTAL,
  workshopSpecialtyEnums.UROLOGY,
  workshopSpecialtyEnums.OTHER
]

export const workshopBaseSpecialtyArray = [
  workshopSpecialtyEnums.OB_GYN,
  workshopSpecialtyEnums.GENERAL,
  workshopSpecialtyEnums.COLORECTAL,
  workshopSpecialtyEnums.UROLOGY
]
