import { AR_CAM_NAV_VIRTUAL_ID, AR_CAM_NAV_VIRTUAL_ANGLED_ID } from '@/residency/views/ar-cam-nav/consts/ar-activities.js'

const arSubActivities = Object.freeze({
  FOCUS: 1,
  FOLLOW: 2,
  TRACE: 3,
  TUBE: 4,
  STAR: 5,
  ANGLED_TUTORIAL: 6
})

export default arSubActivities

export const isArTutorial = (subActivityId) => {
  return subActivityId === arSubActivities.ANGLED_TUTORIAL
}

export const arSubActivityNames = Object.freeze({
  [arSubActivities.FOCUS]: 'FOCUS',
  [arSubActivities.FOLLOW]: 'FOLLOW',
  [arSubActivities.TRACE]: 'TRACE',
  [arSubActivities.TUBE]: 'TUBE',
  [arSubActivities.STAR]: 'STAR',
  [arSubActivities.ANGLED_TUTORIAL]: 'TUTORIAL'
})

export const arSubActivityTitles = Object.freeze({
  [arSubActivities.FOCUS]: 'Focus',
  [arSubActivities.FOLLOW]: 'Follow',
  [arSubActivities.TRACE]: 'Trace',
  [arSubActivities.TUBE]: 'Tube Targeting',
  [arSubActivities.STAR]: 'Star Pursuit',
  [arSubActivities.ANGLED_TUTORIAL]: 'Tutorial Activity'
})

export const arSubActivityIdToArActivityIdMapping = Object.freeze({
  [arSubActivities.FOCUS]: AR_CAM_NAV_VIRTUAL_ID,
  [arSubActivities.FOLLOW]: AR_CAM_NAV_VIRTUAL_ID,
  [arSubActivities.TRACE]: AR_CAM_NAV_VIRTUAL_ID,
  [arSubActivities.TUBE]: AR_CAM_NAV_VIRTUAL_ANGLED_ID,
  [arSubActivities.STAR]: AR_CAM_NAV_VIRTUAL_ANGLED_ID,
  [arSubActivities.ANGLED_TUTORIAL]: AR_CAM_NAV_VIRTUAL_ANGLED_ID
})
