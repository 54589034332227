import alertTypes from '@/components/alert/alert-types'

export const loginErrorProps = {
  connectionError: {
    alertType: alertTypes.ERROR,
    title: 'Connection Error',
    text: 'Application error. Connection to the server could not be established.'
  },
  badCredentiaslError: {
    alertType: alertTypes.ERROR,
    title: 'Incorrect Username or Password',
    text: 'We don\'t recognize that username or password. Please check your login credentials and try again.'
  },
  userLockedError: {
    alertType: alertTypes.ERROR,
    title: 'User Account is Locked',
    text: 'You have been locked out for 15 minutes due to too many failed login attempts.'
  },
  userDisabledError: {
    alertType: alertTypes.ERROR,
    title: 'User Account is Disabled',
    text: 'This account is not yet registered. Please check your email to access the registration link.'
  },
  unstartedProgramError: {
    alertType: alertTypes.ERROR,
    title: 'Program Not Started',
    text: 'This program has not begun. Please check later once the program has been activated.'
  },
  notFoundError: {
    alertType: alertTypes.ERROR,
    title: 'Not Found',
    text: 'No active programs were found for your account.'
  },
  internalServerError: {
    alertType: alertTypes.ERROR,
    title: 'Internal Server Error',
    text: 'A server error has occurred. Service may be temporarily down.'
  },
  genericError: {
    alertType: alertTypes.ERROR,
    title: 'Error',
    text: 'An error has occurred.'
  },
  userRoleMaintenanceError: {
    alertType: alertTypes.ERROR,
    title: 'Under Maintenance',
    text: 'The system coordinator role is currently under maintenance.\nPlease check back again later!'
  }
}
